<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <h2 class="mb-5">Empirical Formula: Prelab Quiz</h2>

      <p class="mb-n3">
        a) Which of the items listed below is not required during this experiment?
      </p>

      <v-radio-group v-model="inputs.notNeeded" class="ml-6 mb-0" :disabled="!allowEditing">
        <v-radio
          v-for="option in optionsNotNeeded"
          :key="'pt-1-' + option.value"
          class="my-1"
          :value="option.value"
        >
          <template #label>
            <stemble-latex :content="option.text" />
          </template>
        </v-radio>
      </v-radio-group>

      <p class="mb-n3">
        b) You should always handle the crucible and its cover with tongs. What happens if you
        handle the crucible and cover with your fingers?
      </p>

      <v-radio-group v-model="inputs.crucibleWithHands" class="ml-6 mb-0" :disabled="!allowEditing">
        <v-radio
          v-for="option in optionsCrucibleWithHands"
          :key="'pt-2-' + option.value"
          class="my-1"
          :value="option.value"
        >
          <template #label>
            <stemble-latex :content="option.text" />
          </template>
        </v-radio>
      </v-radio-group>

      <p class="mb-n3">
        c) If you weigh the crucible and crucible lid together, instead of separately, and the lid
        breaks apart in the middle of the experiment, do we have to start the experiment again?
      </p>

      <v-radio-group v-model="inputs.breakLid" class="ml-6 mb-0" :disabled="!allowEditing">
        <v-radio
          v-for="option in optionsBreakLid"
          :key="'pt-3-' + option.value"
          class="my-1"
          :value="option.value"
        >
          <template #label>
            <stemble-latex :content="option.text" />
          </template>
        </v-radio>
      </v-radio-group>

      <p class="mb-n3">d) What is the purpose of adding water during the experimental procedure?</p>

      <v-radio-group v-model="inputs.waterAddition" class="ml-6 mb-0" :disabled="!allowEditing">
        <v-radio
          v-for="option in optionsWaterAddition"
          :key="'pt-4-' + option.value"
          class="my-1"
          :value="option.value"
        >
          <template #label>
            <stemble-latex :content="option.text" />
          </template>
        </v-radio>
      </v-radio-group>

      <p class="mb-n3">e) Why will the crucible and its contents gain mass when heated?</p>

      <v-radio-group v-model="inputs.massGain" class="ml-6 mb-0" :disabled="!allowEditing">
        <v-radio
          v-for="option in optionsMassGain"
          :key="'pt-5-' + option.value"
          class="my-1"
          :value="option.value"
        >
          <template #label>
            <stemble-latex :content="option.text" />
          </template>
        </v-radio>
      </v-radio-group>

      <p class="mb-2">
        f) Assume that you started with
        <number-value :value="massMg" unit="\text{g}" />
        and obtained
        <latex-number :number="massMgO.toFixed(3)" unit="\text{g}" />
        of the dry oxide at the end. What is the molar ratio of magnesium to oxygen in your product?
      </p>

      <v-radio-group v-model="inputs.ratio" class="ml-6 mb-0" :disabled="!allowEditing">
        <v-radio
          v-for="option in optionsRatio"
          :key="'pt-6-' + option.value"
          class="my-1"
          :value="option.value"
        >
          <template #label>
            <stemble-latex :content="option.text" />
          </template>
        </v-radio>
      </v-radio-group>
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import StembleLatex from '@/tasks/components/StembleLatex';
import NumberValue from '@/tasks/components/NumberValue';
import LatexNumber from '@/tasks/components/displayers/LatexNumber.vue';

export default {
  name: 'OleMissLab7Prelab',
  components: {LatexNumber, NumberValue, StembleLatex},
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      // Put a list of inputs or computed properties here to be sent for marking
      inputs: {
        notNeeded: null,
        crucibleWithHands: null,
        breakLid: null,
        waterAddition: null,
        massGain: null,
        ratio: null,
      },
      optionsNotNeeded: [
        {text: 'Oxide strip', value: 'oxide'},
        {text: 'Mg strip', value: 'mg'},
        {text: 'Tongs', value: 'tongs'},
        {text: 'Burner', value: 'burner'},
      ],
      optionsCrucibleWithHands: [
        {text: 'Hot Objects may hurt you!', value: 'oww'},
        {text: 'Transfers oils and dirt from your fingers to the crucible', value: 'oil'},
        {text: 'All other answers are correct', value: 'allOthers'},
        {
          text: 'Inaccurate Mg:O ratio due to oil and dirt from your fingers',
          value: 'inaccurateRatio',
        },
      ],
      optionsBreakLid: [
        {text: 'No, continue the experiment without a lid', value: 'noLid'},
        {text: 'Yes, we have to start again', value: 'startAgain'},
        {
          text: 'No, continue the experiment with a new lid and use the same mass as the original',
          value: 'newLid',
        },
      ],
      optionsWaterAddition: [
        {text: 'Hydrolysis of magnesium nitride', value: 'hydroMgN'},
        {text: 'Hydrolysis of magnesium oxide', value: 'hydroMgO'},
        {text: 'Hydrolysis of ammonia', value: 'hydroNH3'},
        {text: 'Pyrolysis of magnesium nitride', value: 'pyroMgN'},
      ],
      optionsMassGain: [
        {
          text: 'Contents of heavy porcelain containers gain weight when we heat them',
          value: 'porcelain',
        },
        {
          text: 'Formation of magnesium oxide and magnesium nitride',
          value: 'formationOfNitrideOxide',
        },
        {text: 'Because of impurities and pollution in the air', value: 'impuritiesInAir'},
        {text: 'All other answers are correct', value: 'allOthers'},
      ],
      optionsRatio: [
        {text: '$\\text{2:1}$', value: '2to1'},
        {text: '$\\text{1:11.01}$', value: '1to11'},
        {text: '$\\text{1:2}$', value: '1to2'},
        {text: '$\\text{1:1}$', value: '1to1'},
        {text: '$\\text{11.01:1}$', value: '11to1'},
      ],
    };
  },
  computed: {
    massMg() {
      return this.taskState.getValueBySymbol('massMg').content;
    },
    massMgO() {
      return this.massMg.toFloat() * 1.65826;
    },
  },
};
</script>
<style scoped></style>
